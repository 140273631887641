/* import tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* import libs */
@import "~nprogress/nprogress.css";
@import "~react-toastify/dist/ReactToastify.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scrollbar design */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 80vh;
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #546ccc;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0d1826;
}

.pattern {
  background: url("./assets/images/hero-pattern.png");
}

.select-filter {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("./assets/images/calendar.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 16px;
  padding-right: 20px;
}

select.select-filter::-ms-expand {
  display: none;
}

@-moz-document url-prefix() {
  select.select-filter {
    background-image: url("./assets/images/calendar.svg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px;
    padding-right: 20px;
  }
}

.scrollbar-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
